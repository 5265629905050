<script setup>
import { Link } from "@inertiajs/vue3";

const props = defineProps({
    href: {
        type: String,
        required: true,
    },
    asVueLink: {
        type: Boolean,
        default: true,
    },
    only: {
        type: Array,
        default: [],
    },
    except: {
        type: Array,
        default: [],
    },
    externalLink: {
        type: Boolean,
        default: false,
    },
});
</script>

<template>
    <Link v-if="props.asVueLink === true" :href="href" :only="props.only" :except="props.except" :target="externalLink ? '_blank' : '_self'" class="inline-flex items-center px-4 py-2 bg-gray-800 dark:bg-gray-200 border border-transparent rounded-md font-semibold text-xs text-white dark:text-gray-800 uppercase tracking-widest hover:bg-gray-700 dark:hover:bg-white focus:bg-gray-700 dark:focus:bg-white active:bg-gray-900 dark:active:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-brand-primary focus:ring-offset-2 dark:focus:ring-offset-gray-800 transition ease-in-out duration-150 disabled:cursor-not-allowed">
        <slot />
    </Link>
    <a v-else :href="href" :target="externalLink ? '_blank' : '_self'" class="inline-flex items-center px-4 py-2 bg-gray-800 dark:bg-gray-200 border border-transparent rounded-md font-semibold text-xs text-white dark:text-gray-800 uppercase tracking-widest hover:bg-gray-700 dark:hover:bg-white focus:bg-gray-700 dark:focus:bg-white active:bg-gray-900 dark:active:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-brand-primary focus:ring-offset-2 dark:focus:ring-offset-gray-800 transition ease-in-out duration-150 disabled:cursor-not-allowed">
        <slot />
    </a>
</template>
